// import logo from '../images/header__logo.svg';
// import './App.css';

function Footer() {
  return (    
    <footer className="footer">
    <p className="footer__copyright">© 2022 Mesto Russia</p>
  </footer>      
  );
}

export default Footer;